<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline"
                    ref="searchRef"
                    :model="searchForm">
        <a-form-model-item prop="search_id"
                           label="ID">
          <a-input style="width:110px"
                   placeholder="请输入ID"
                   v-model="search_id" />
        </a-form-model-item>
        <a-form-model-item prop="phone"
                           label="手机">
          <a-input style="width:130px"
                   placeholder="请输入手机号"
                   v-model="searchForm.phone" />
        </a-form-model-item>
        <a-form-model-item prop="outlets_city_adcode"
                           label="开卡城市">
          <a-select
            ref="selectCity"
            v-model="searchForm.outlets_city_adcode"
            style="width:100px;"
            placeholder="请选择开卡城市"
          >
            <a-select-option value>不限</a-select-option>
            <a-select-option
              v-for="(item, index) in cityList"
              :key="index"
              :label="item.name"
              :value="item.adcode"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="card_no"
                           label="钱包编号">
          <a-input style="width:200px"
                   placeholder="钱包编号（微信会员卡号）"
                   v-model="searchForm.card_no" />
        </a-form-model-item>
        <a-form-model-item prop="effective_time"
                           label="开卡日期">
          <a-range-picker style="width:250px"
                          v-model="searchForm.effective_time"
                          valueFormat="YYYY-MM-DD" />
        </a-form-model-item>
        <a-form-model-item>
          <a-checkbox class="ml-2"
              v-model="isSortbyIntegral">按积分</a-checkbox>
          <a-button class="ml-2" 
                    @click="handlerResetForm">重置</a-button>
          <a-button class="ml-2" type="primary" html-type="submit" 
                    :loading="isLoading"
                    @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <div class="flex justify-between">
        <span class="text-lg font-bold p-3">会员列表</span>
      </div>
    </div>

    <base-table :columnsData="columns"
                :tableData="tableData"
                :getListFunc="initData"
                :total="total"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #nickname="{record}">
        <a-tag v-if="record.close_account == 2" color="red">已注销</a-tag>
        {{record.nickname}}
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerDetail(record.member_id)">详情</a-button>
        <!-- <a-button type="link" @click="handlerMemberOrder(record)">订单</a-button> -->
      </template>
    </base-table>

    <a-drawer :title="'会员详情（uid: '+memberId+'）'"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false">
      <vip-detail :memberId="memberId" v-if="isShowDetail" />
    </a-drawer>

  </div>
</template>

<script>
import pageData from "./columns"
import { getVipList } from "@/api/vip.js"
import { getCityList } from "@/api/outlets"

import VipDetail from './detail.vue'
export default {
  components: { VipDetail },
  data () {
    return {
      ...pageData,
      activeKey: 0,
      isShowDetail: false,
      memberId: '',
      cityList: [],
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      total: 0,
      search_id: "",
      searchForm: {
        name: "",
        phone: "",
        outlets_city_adcode: "",
        card_no: "",
        effective_time: [],
        effective_time_end: "",
        effective_time_start: "",
        date_type: 0,
        page: 1,
        page_count: 20,
        sort: "",
      },
      isSortbyIntegral: false,
      tableData: [],

      isLoading: false,
    }
  },
  mounted () {
    this.initData()
    this.initCityList()
  },
  methods: {

    async initCityList() {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },

    async initData () {
      const params = this.searchForm
      if (this.searchForm.effective_time.length > 0) {
        params.open_card_time_start = this.searchForm.effective_time[0]
        params.open_card_time_end = this.searchForm.effective_time[1]
      }else{
        params.open_card_time_start = ""
        params.open_card_time_end = ""
      }
      this.isLoading = true
      const { data, code } = await getVipList(params)
      this.isLoading = false
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 搜索
    handlerSearch () {
      let search_id = parseInt(this.search_id) || 0
      if(search_id){
        if(search_id > 100000000){
          search_id = search_id-100000000
        }
        this.handlerDetail(search_id)
      }else{
        this.search_id = ""
        this.searchForm.page = 1
        this.searchForm.sort = this.isSortbyIntegral?"2":""
        this.initData()
      }
    },
    // 重置
    handlerResetForm () {
      this.search_id = ""
      this.searchForm.phone = ""
      this.searchForm.effective_time = []
      this.searchForm.open_card_time_end = ""
      this.searchForm.open_card_time_start = ""
      this.searchForm.page = 1
      this.searchForm.outlets_city_adcode = ""
      this.$refs.searchRef.resetFields()
      // this.initData()
    },
    handlerDetail (member_id) {
      this.memberId = member_id
      this.isShowDetail = true
    },
    // handlerMemberOrder(row){
    //   const newPage = this.$router.resolve({
    //     path: "/order",
    //     query: { memberId: row.member_id },
    //   })
    //   window.open(newPage.href, "_blank")
    // }

  },
}
</script>

<style>
</style>